import React from "react"

import Layout from "../components/layout"
import { GatsbyImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import Seo from "../components/seo"

import Grid from '@mui/material/Grid';

const IndexPage = () => {
  const data = useStaticQuery(graphql`{
    allFile(
      sort: {order: DESC, fields: name}
      filter: { sourceInstanceName: { eq: "musicReleases" } }
    ) {
        edges {
          node {
            relativePath
            name
            extension
            childImageSharp {
              gatsbyImageData(width: 800, height: 800, layout: CONSTRAINED)
            }
          }
        }
      }
  }
`)

  return (
    <Layout>
      <Seo title="Record Label" />
        <div>
          <Grid container spacing={4} justifyContent="flex-start">
            {data.allFile.edges.map(edge => {
              // take song url name from file name
              
              const presaveName = edge.node.name.slice(3)

              return (
                <Grid item xs={6} md={3} key={edge.node.name}>
                  <a href={`https://bfan.link/${presaveName}`} target="_blank" rel="noopener noreferrer" >
                    <GatsbyImage image={edge.node.childImageSharp.gatsbyImageData} alt={edge.node.name}/>
                  </a>
                </Grid>
              )
            })}
          </Grid>
          
        </div>
      
    </Layout>
  );}

export default IndexPage
